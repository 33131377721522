@import "react-datepicker/dist/react-datepicker.css";

@import "components/nprogress";
@import "components/scrollbar";
@import "components/show-more-text";

.react-datepicker-popper {
  z-index: var(--chakra-zIndices-docked);
}

.react-datepicker__navigation-icon {
  font-size: initial;
}

.table-sticky {
  border-collapse: separate !important;

  thead {
    th {
      background-color: var(--chakra-colors-gray-100);
      position: sticky;
      top: 0;
      z-index: 2;

      &:first-child {
        left: 0;
        z-index: 3;
      }
    }
  }

  tbody {
    td:first-child {
      position: sticky;
      left: 0;
      background-color: var(--chakra-colors-gray-100);
      z-index: 2;
    }
  }
}
